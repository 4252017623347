<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <Chart1 title="Online" />
      </v-col>
      <v-col cols="12" md="6">
        <Chart1 title="Visits" />
      </v-col>
      <v-col cols="12" md="6">
        <Chart1 title="New Users" />
      </v-col>
      <v-col cols="12" md="6">
        <Chart1 title="Verified" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <VTable title="Title Here"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

// Utilities
import { initComponent } from '@/lib'
import { mapState } from 'vuex'

export default initComponent ('Dashboard', {
  isPage: true,
  components : {
    Chart1: () => import('./components/widgets/ChartOne'),
    VTable: () => import('./components/widgets/Table'),
  },
  computed: {
    ...mapState({
      //..
    }),
  },
  methods: {
    async onReady () {
      console.log('Dashboard');
      return
    },
  }
})
</script>
